import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/cha/assinar-premium": [~3],
		"/cha/assinar-premium/dados-do-cartao": [~4],
		"/cha/assinar-premium/dados-do-pix": [~5],
		"/cha/assinar-premium/modo-de-pagamento": [~6],
		"/cha/assinar-premium/pagamento": [7],
		"/cha/assinar-premium/pix": [8],
		"/cha/bem-vinda": [~9],
		"/cha/compartilhar-cha": [~10],
		"/cha/dados-pessoais": [~11],
		"/cha/dados-pessoais/update-dados-pessoais": [12],
		"/cha/definir-resultado": [~13],
		"/cha/definir-resultado/inserir-genero": [~14],
		"/cha/definir-resultado/inserir-genero/select-gender": [~15],
		"/cha/definir-resultado/inserir-genero/select-gender/congratulations": [16],
		"/cha/definir-resultado/tutorial": [17],
		"/cha/historico-da-votacao": [~18],
		"/cha/meus-presentes": [~19],
		"/cha/meus-presentes/gift-extract": [~20],
		"/cha/meus-presentes/register-bank-account": [~21],
		"/cha/meus-presentes/register-bank-account/register": [22],
		"/cha/meus-presentes/register-pix-key": [~23],
		"/cha/meus-presentes/register-pix-key/register": [24],
		"/cha/meus-presentes/request-gifts": [~25],
		"/cha/meus-presentes/request-gifts/confirm-request/success-screen": [27],
		"/cha/meus-presentes/request-gifts/confirm-request/[id]": [~26],
		"/cha/meus-presentes/request-gifts/edit-account/[id]": [~28],
		"/cha/meus-presentes/request-gifts/edit-pix-key/[id]": [~29],
		"/cha/para-comecar": [~30],
		"/cha/personalizar-revelacao": [~31],
		"/cha/preview": [32],
		"/cha/profile/[uuid]": [~33],
		"/cha/profile/[uuid]/result": [~34],
		"/cha/profile/[uuid]/result/share-link-gender": [~35],
		"/cha/selecao-de-data": [~36],
		"/cha/selecao-de-emoji": [~37],
		"/cha/selecao-de-nomes": [~38],
		"/cha/tema": [~39],
		"/checking-reveals": [~40],
		"/congratulations": [41],
		"/donations/[revealId]": [~42],
		"/donations/[revealId]/dados-do-cartao": [~43],
		"/donations/[revealId]/dados-do-doador": [~44],
		"/donations/[revealId]/dados-do-pix": [~45],
		"/donations/[revealId]/modo-de-pagamento": [~46],
		"/donations/[revealId]/pagamento": [~47],
		"/donations/[revealId]/pix": [~48],
		"/donations/[revealId]/valor-personalizado": [~49],
		"/error/front": [50],
		"/login": [~51],
		"/login/create-account": [~52],
		"/login/create-account/personal-data": [~53],
		"/login/create-account/personal-data/register-personal-data": [54],
		"/login/entrar": [~55],
		"/login/entrar/checking-account": [56],
		"/login/esqueci-minha-senha": [~57],
		"/login/esqueci-minha-senha/confirmando-email": [58],
		"/login/esqueci-minha-senha/nova-senha": [~59],
		"/login/esqueci-minha-senha/nova-senha/reset-password": [60],
		"/meu-cha/[reveal_id]": [~61],
		"/notice-screen": [~62],
		"/notice-screen/select-gender": [~63],
		"/sentry-example": [64],
		"/suporte": [65],
		"/transicao": [66]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';